import axios from '@/common/axios'
import store from '@/store'
import conf from '@/common/conf.js'
import define from "@/common/define"
// 读取账号列表
const getAdminList = (form) => {
    let params = {}
    params.keyword = form.keyword
    params.pageNumber = form.pageNo
    params.pageSize = form.pageSize

    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)

    return axios({
        url: conf.apiUrl + '/member/index',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 下拉角色列表
const roleList = () => {
    let data = {}
    return axios({
        url: conf.apiUrl + '/member/roleList',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 添加账号
const adminAdd = (row) => {
    let params = {}
    params.username = row.user_name
    params.password = row.password
    params.nickname = row.nick_name
    params.phone = row.phone
    params.face = row.face
    params.role = row.roles
    params.status = row.status
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/member/add',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 编辑账号
const adminEdit = (row) => {
    let params = {}
    params.username = row.user_name
    params.password = row.password
    params.nickname = row.nick_name
    params.phone = row.phone
    params.face = row.face
    params.role = row.roles
    params.status = row.status
    params.uid = row.uid
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/member/edit',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data,
    })
}

// 删除账号
const adminDel = (row) => {
    let params = {}
    params.uid = row.uid
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/member/del',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 批量删除
const batchRemove = (row) => {
    let params = {}
    params.uid = row.uid
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/member/batchRemove',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 账号（修改密码）
const doAdminPassword = (row) => {
    let params = {}
    params.password = row.password
    params.source = row.source
    params.again_password = row.again_password
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/member/upPassword',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data: data
    })
}

// 列表员工状态滑块修改
const statusEdit = (row) => {
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(row, time)
    return axios({
        url: conf.apiUrl + '/member/statusEdit',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 输出
export default {
    getAdminList,
    adminAdd,
    adminEdit,
    adminDel,
    doAdminPassword,
    statusEdit,
    roleList,
    batchRemove
}
