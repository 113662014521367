import arAes from '@/js_sdk/ar-aes/ar-aes.js'

/**
 * 定义
 */
const defineStr = {
  65: 'D',
  66: 'n',
  67: 'E',
  68: 'F',
  69: '3',
  70: 'G',
  71: 'H',
  72: 'j',
  73: 'k',
  74: 'I',
  75: '6',
  76: 'J',
  77: 'K',
  78: 'M',
  79: 'N',
  80: 'P',
  81: 'Q',
  82: 'R',
  83: 'S',
  84: 'p',
  85: 'q',
  86: '=',
  87: 'B',
  88: 'T',
  89: '8',
  90: 'U',
  97: 'V',
  98: 'W',
  99: 'o',
  100: '/',
  101: 'Z',
  102: 'a',
  103: 'b',
  104: 'c',
  105: '7',
  106: 'd',
  107: 'e',
  108: 'f',
  109: 'g',
  110: 'h',
  111: 'i',
  112: 'l',
  113: 'm',
  114: 'r',
  115: 's',
  116: 't',
  117: 'u',
  118: '+',
  119: 'v',
  120: 'w',
  121: 'C',
  122: 'x',
  48: 'A',
  49: 'y',
  50: 'z',
  51: 'L',
  52: '0',
  53: '1',
  54: '2',
  55: 'X',
  56: 'Y',
  57: '4',
  43: '5',
  47: '9',
  61: 'O'
}

/**
 * 转码
 */
const transcode = (text, time) => {
  let textLen = text.length
  let charNum = 0
  let result = ''

  for (let i = 0; i < textLen; i++) {
    charNum = ((time * 2 - 1703132600) % (textLen + i)) % 3

    switch (charNum) {
      case 0:
        if ((i % 2) === 0) {
          result += text[i]
        } else {
          result += defineStr[text.charCodeAt(i)]
        }
        break
      case 1:
        if ((i % 2) === 1) {
          result += text[i]
        } else {
          result += defineStr[text.charCodeAt(i)]
        }
        break
      case 2:
        if ((i % 3) === 0) {
          result += defineStr[text.charCodeAt(i)]
        } else {
          result += text[i]
        }
        break
    }
  }

  return result
}

/**
 * 转参
 */
const defineParam = (text, time) => {
  let type = typeof text

  if (type === 'object') {
    text = JSON.stringify(text)
  }

  return transcode(arAes.AES.encrypt(text, 'xkT31Q0BXvfIWJ05', 'nnLkVLht2DwDpo9c'), time)
}

// 输出
export default {
  defineParam
}