import axios from 'axios';
import router from '@/router'
import store from '@/store'
import common from '@/common/common.js'
import conf from '@/common/conf.js'
import { ElNotification } from 'element-plus'
const instance = axios.create({
  baseURL: conf.apiUrl, // 替换为你的 API 基础 URL
  timeout: 60000 // 设置请求超时时间
});
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 检查是否存在响应对象，并且响应状态码为 429
    if (error.response && error.response.status == 429) {
      ElNotification({
        title: '请求过于频繁',
        message: '点击频率过高，请稍后刷新页面',
        type: 'error',
        duration: '5000'
      });
      // 这里可以添加其他处理逻辑，例如暂停一段时间再重试请求
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 10000); // 等待5秒后重试
      });
    }
    // 对响应错误做点什么
    else if (error.response && error.response.status == 500) {
      ElNotification({
        message: '接口500',
        type: 'error',
        duration: '2000'
      })
    } else if (error.response && error.response.status == 400) {
      ElNotification({
        message: error.response.data.msg,
        type: 'error',
        duration: '2000'
      })
      // 清除本地存储或 Vuex 中的 token
      // 清空登录信息
      store.commit('modifyToken', { token: '', expire: 0 })
      store.commit('modifyRole', '')
      common.setUserInfo('')
      common.setSystenInfo('')
      // 引导用户到登录界面
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

export default instance;