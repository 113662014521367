<template>
  <router-view />
  <global-dialog ref="isURL"/>
</template>

<script>
import GlobalDialog from '@/components/global-dialog.vue';
export default {
  provide() {
    return {
      toggleDialog: this.toggleDialog
    }
  },
  name: "App",
  components: {
    GlobalDialog,
  },
  data() {
    return {
      isURL: false
    }
  },
  methods: {
    toggleDialog(show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs.isURL.show()
        })
      }

    },

  }
}
</script>

<style lang="scss">

</style>
