<!-- eslint-disable -->
<template>
  <div class="flex">
    <div class="left-menu">
      <div>
        <div class="left-menu-logo ">
          <img class="image" src="@/assets/layout/small_logo.png" style="width: 30px;" v-if="twoMenuShow" />
          <img class="image" src="@/assets/layout/logo.png" style='width:140px' v-else />
        </div>
        <div class="left-menu-main">
          <el-menu class="el-menu-vertical-demo" router :collapse="twoMenuShow" text-color="#AAAAAA"
            active-text-color="#FFFFFF" background-color="#08192E" :default-active="activeFun" unique-opened >
            <template v-for="route in topRoutes" :key="route.name">
              <div v-show="route.children && route.children.length > 0">
                <el-sub-menu :index="route.name">
                  <template #title>
                    <el-icon>
                      <component :is="route.meta.icon" size="16"></component>
                    </el-icon>
                    <span style="" v-show="!twoMenuShow">{{ route.meta.title }}</span>
                  </template>
                  <el-menu-item v-for="child in route.children" :index="child.path" :key="child.name"
                    :route="{ name: child.name }" style="padding-left: 40px;" >
                    <el-icon>
                      <component :is="child.meta.icon" size="16"></component>
                    </el-icon>
                    {{ child.meta.title }}
                  </el-menu-item>
                </el-sub-menu>
              </div>
              <div v-show='!route.children || route.children.length == 0'>
                <!-- 没有子菜单的菜单项 -->
                <el-menu-item :index="route.path" :route="{ name: route.path }" style="padding-left:20px;">
                  <el-icon>
                    <component :is="route.meta.icon" size="16"></component>
                  </el-icon>
                  <template #title>
                    <span style="font-size: 14px;">{{ route.meta.title }}</span>
                  </template>
                </el-menu-item>
              </div>
            </template>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="flex-one width-zero">
      <div class="top-nav">
        <div class="top-menu">
          <div class="top-menu-left">
            <div class="m-t-6">
              <component :is="topFoldIcon" class="top-fold-icon" size="16" @click="menuFold()">
              </component>
            </div>
            <div class="top-breadcrumb">
              <div class="flex">
                <div class="m-t-5">
                  <component :is="breadcrumbList[0].icon" size="16" />
                </div>
                <span class="m-l-2">{{ breadcrumbList[0].title }}</span>
              </div>
              <div class="m-l-5 m-t-5">
                <icon-right size="12" />
              </div>
              <div class="flex m-l-5">
                <div class="m-t-5">
                  <component :is="breadcrumbList[1].icon" size="16" />
                </div>
                <span class="m-l-2">{{ breadcrumbList[1].title }}</span>
              </div>
            </div>
          </div>
          <div class="top-menu-right">
            <!-- <div>
              <img class="top-menu-avatar" :src="`${userInfo.avatar}`" v-if="userInfo.avatar" />
              <img class="top-menu-avatar" src="@/assets/layout/avator.png" v-else />
            </div> -->
            <el-dropdown class="top-menu-dropdown m-l-10">
              <span>
                <span>{{ userInfo.nickname }}</span>
                <!-- <icon-down class="top-dropdown-icon" size="16" /> -->
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="$refs['password'].show()">
                    <icon-key class="m-t-5" size="16" />
                    <span class="m-l-2">修改密码</span>
                  </el-dropdown-item>
                  <el-dropdown-item @click="logout()">
                    <icon-logout class="m-t-5" size="16" />
                    <span class="m-l-2">退出登录</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <component style="cursor: pointer;" :is="power" size="14" @click="logout()" class="m-l-15 m-t-3" />
            <component style="cursor: pointer;" :is="screen" size="14" @click="toggleFullScreen"
              class="m-l-10 m-t-3" />
          </div>
        </div>
        <div class="top-tab">
          <icon-left class="tab-icon" size="16" v-show="tabIconShow" @click="tabViewBegin()" style="" />
          <div class="tab-view" ref="tabView">
            <div class="tab-list" ref="tabList">
              <div class="tab-item" :class="tabActiveClass('index')" :ref="tabItemRef('index')" @click="toRoute('index')">
                <icon-chart-histogram class="item-icon" size="16" />
                <span class="m-l-5">今日数据</span>
              </div>
              <div v-for="(item, key) in tabList" :key="key" class="tab-item move-left" :class="tabActiveClass(item.name)"
                :ref="tabItemRef(item.name)" @click="toRoute(item.name)">
                <el-icon class="item-icon" :size="16">
                  <component :is="item.icon"></component>
                </el-icon>
                <span class="m-l-5">{{ item.title }}</span>
                <el-icon :size="12" class="tab-item-close" @click.stop="tabClose(item.name)">
                  <Close />
                </el-icon>
              </div>
            </div>
          </div>
          <icon-right class="tab-icon" size="16" v-show="tabIconShow" @click="tabViewEnd()" />
        </div>
      </div>
      <el-scrollbar height="calc(100vh - 110px)">
        <router-view v-slot="{ Component }">
          <keep-alive :include="keepAliveList">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </el-scrollbar>
    </div>
    <AdminPassword ref="password" />
    <el-dialog v-model="closevisit" title="温馨提示" width="30%">
      <span>确定注销并退出系统吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closevisit = false">取消</el-button>
          <el-button type="primary" @click="closeLyout">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import common from "@/common/common.js";
import AdminPassword from "@/components/nav/AdminPassword.vue";
import { Close } from "@element-plus/icons-vue";

export default {
  name: "HomeNav",
  components: { AdminPassword, Close },
  data() {
    return {
      power:'icon-power',
      screen:'icon-full-screen-one',
      activeFun: 'index',
      collapsed: false,
      closevisit: false,
      allmenu: [],
      // 一级菜单（当前的）
      oneMenuList: [],
      // 二级菜单列表（所有的）
      twoMenuAll: {},
      // tab激活的
      tabActive: "",
      // tab列表
      tabList: [],
      // keep-alive 缓存页列表
      keepAliveList: ["index"],
      // tab左右箭头图标（是否显示）
      tabIconShow: false,
      // tab list 偏移距离
      tabAmendNum: 0,
      // 二级菜单是否显示
      twoMenuShow: false,
      // 二级菜单折叠图标
      topFoldIcon: "icon-menu-fold",
      // 用户信息
      userInfo: {},
      topRoutes: [],
    };
  },
  created() {
    // 读取用户信息（判断是否最终页前执行，否则跳转页面后created不会再次触发！）
    this.userInfo = common.getUserInfo();
    // 读取菜单列表配置
    this.getMenuList();
    this.defaultOpeneds = this.getDefaultOpeneds();
    // 非最终显示页，跳转到二级显示页
    if (this.twoMenuAll[this.$route.name]) {
      // 跳转不会直接阻止后续代码执行
      this.toRoute(this.$route.name);
      return false;
    }
    // 设置当前激活的一级、二级菜单
    this.setctive();
    // tab打开
    this.tabOpen(this.$route.name);
  },
  computed: {
    // 传参菜单是否是激活的tab
    tabActiveClass() {
      return (tabName) => {
        return tabName === this.tabActive ? "active" : "";
      };
    },
    // tab item ref 命名
    tabItemRef() {
      return (tabName) => {
        return "tabItem" + tabName;
      };
    },
    // 面包屑
    breadcrumbList() {
      let breadcrumb = [{}, {}];
      switch (this.$route.matched.length) {
        case 2:
          breadcrumb[0].title = this.$route.matched[1].meta.title;
          breadcrumb[0].icon = this.$route.matched[1].meta.icon;
          break;
        case 3:
          breadcrumb[0].title = this.$route.matched[1].meta.title;
          breadcrumb[0].icon = this.$route.matched[1].meta.icon;
          breadcrumb[1].title = this.$route.matched[2].meta.title;
          breadcrumb[1].icon = this.$route.matched[2].meta.icon;
          break;
      }
      return breadcrumb;
    },
  },
  watch: {
    $route(newRoute) {
      this.$nextTick(() => {
        this.defaultOpeneds = this.getDefaultOpeneds();
      });
      // noLogin时，不执行后续逻辑
      if (newRoute.meta.noLogin) {
        return false;
      }
      // 非最终显示页，跳转到二级显示页
      if (this.twoMenuAll[newRoute.name]) {
        // 跳转不会直接阻止后续代码执行
        this.toRoute(newRoute.name);
        return false;
      }
      // 设置当前激活的一级、二级菜单
      this.setctive();
      // tab打开
      this.tabOpen(newRoute.name);
      // tab显示区域计算
      this.$nextTick(function () {
        this.tabViewOpen();
      });
    },
  },
  mounted() {
    // tab显示区域计算
    this.tabViewOpen();
  },
  methods: {
    handleSelect(index, indexPath) {
      // 当点击子项时，关闭其他主项
      if (indexPath.length > 1) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
    },
    // 跳转到消息通知
    packageExpiration() {
      this.$router.push({
        name: "packageExpiration",
      })
    },
    // 全屏操作
    toggleFullScreen() {
      // 如果已经是全屏，则退出
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        // 否则请求全屏
        let element = document.documentElement;  // 获取根元素，即全屏显示整个 web 应用
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
          element.msRequestFullscreen();
        }
      }
    },
    getDefaultOpeneds() {
      // 当前活跃路由对应的完整路径
      const activePath = this.$route.path;
      // 将激活路径分割成数组
      const paths = activePath.split('/').filter(path => path);
      var lastElement = paths[paths.length - 1];
      var string = String(lastElement);
      this.activeFun = string
    },
    // 菜单跳转
    toRoute(routeName) {
      // 非最终显示页时，改变跳转地址
      if (this.twoMenuAll[routeName]) {
        routeName = this.twoMenuAll[routeName][0]["name"];
      }
      // 非本页时，跳转
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    // 读取菜单列表配置
    getMenuList() {
      let topRoutes = this.$router.options.routes.find(r => r.path === '/').children;
      this.oneMenuList = [];
      this.twoMenuAll = {};
      for (let one of topRoutes) {
        if (common.routeAuth(one.meta.guard)) {
          if (one.children) {
            this.twoMenuAll[one.name] = [];
            for (let two of one.children) {
              if (common.routeAuth(two.meta.guard)) {
                this.twoMenuAll[one.name].push({
                  name: two.name,
                  path: two.path,
                  meta: two.meta,
                });
              }
            }
          }
          this.topRoutes.push({
            name: one.name,
            path: one.path,
            meta: one.meta,
            children: this.twoMenuAll[one.name],
          });
        }
      }
    },
    // 设置当前激活的一级、二级菜单
    setctive() {
      switch (this.$route.matched.length) {
        case 2:
          this.tabActive = this.$route.matched[1].name;
          break;
        case 3:
          this.tabActive = this.$route.matched[2].name;
          break;
      }
    },
    // taba打开
    tabOpen(tabName) {
      let index = this.keepAliveList.indexOf(tabName);
      if (index === -1 && !this.twoMenuAll[tabName]) {
        // 添加keepAliveList列表
        this.keepAliveList.push(tabName);
        // 添加tab列表
        this.tabList.push({
          name: this.$route.name,
          title: this.$route.meta.title,
          icon: this.$route.meta.icon,
        });
      }
    },
    // tab关闭
    tabClose(tabName) {
      let index = this.keepAliveList.indexOf(tabName);
      if (index > -1) {
        // 关闭的tab宽度（留存）
        let closeRef = this.$refs["tabItem" + tabName];
        if (closeRef.length) {
          closeRef = closeRef[0];
        }
        let closeWidth = closeRef.offsetWidth;
        // 在keepAlive缓存中删除
        this.keepAliveList.splice(index, 1);
        // 在tab列表中删除
        this.tabList.splice(index - 1, 1);
        // 若关闭的是当前tab，则需要跳转
        if (tabName === this.$route.name) {
          this.toRoute(this.keepAliveList[this.keepAliveList.length - 1]);
        }
        // tab显示区域计算
        this.$nextTick(function () {
          this.tabViewClose(closeWidth);
        });
      }
    },
    // tab显示区域计算（新开）
    tabViewOpen() {
      // 2个箭头加一起的宽度
      let tabIconWidth = 32;
      // 实际区域 - 显示区域
      let distance = this.$refs.tabList.offsetWidth - this.$refs.tabView.offsetWidth;
      // 判断是否需要左右偏移箭头
      if (distance > 0) {
        let tabVeiwWidth = this.$refs.tabView.offsetWidth;
        if (this.tabIconShow === false) {
          // 修正因显示箭头而缩小的显示宽度
          tabVeiwWidth = tabVeiwWidth - tabIconWidth;
          // 显示偏移箭头
          this.tabIconShow = true;
        }
        // 激活的元素
        let activeRef = this.$refs["tabItem" + this.tabActive];
        if (activeRef.length) {
          activeRef = activeRef[0];
        }
        // 判断激活的元素是否在显示区域内
        let activeLeftNow = activeRef.offsetLeft - this.tabAmendNum;
        let avtiveRightNow =
          activeRef.offsetLeft + activeRef.offsetWidth - this.tabAmendNum;
        if (activeLeftNow < 0) {
          // 重新计算偏移位置（偏左）
          let amend = activeRef.offsetLeft;
          this.tabAmendNum = amend;
          this.$refs.tabList.style.transform = "translateX(-" + amend + "px)";
        } else if (avtiveRightNow > tabVeiwWidth) {
          // 重新计算偏移位置（偏右）
          let amend =
            activeRef.offsetLeft + activeRef.offsetWidth - tabVeiwWidth;
          this.tabAmendNum = amend;
          this.$refs.tabList.style.transform = "translateX(-" + amend + "px)";
        }
      } else {
        // 关闭偏移箭头
        this.tabIconShow = false;
      }
    },
    // tab显示区域计算（关闭）
    tabViewClose(closeWidth) {
      // 判断偏移量是否需要修正
      if (this.tabAmendNum > 0) {
        // 偏移量减少
        this.tabAmendNum = this.tabAmendNum - closeWidth;
        if (this.tabAmendNum < 0) {
          this.tabAmendNum = 0;
        }
        // 改变偏移样式
        this.$refs.tabList.style.transform =
          "translateX(-" + this.tabAmendNum + "px)";
      }

      // 实际区域 - 显示区域
      let distance =
        this.$refs.tabList.offsetWidth - this.$refs.tabView.offsetWidth;
      // 判断是否需要左右偏移箭头
      if (distance > 0) {
        if (this.tabIconShow === false) {
          // 显示偏移箭头
          this.tabIconShow = true;
        }
      } else {
        // 关闭偏移箭头
        this.tabIconShow = false;
      }
    },
    // tab显示区域回到起始位置
    tabViewBegin() {
      this.tabAmendNum = 0;
      this.$refs.tabList.style.transform = "translateX(0px)";
    },
    // tab显示区域转到结束位置
    tabViewEnd() {
      let amend =
        this.$refs.tabList.offsetWidth - this.$refs.tabView.offsetWidth;
      if (amend > 0) {
        this.tabAmendNum = amend;
        this.$refs.tabList.style.transform = "translateX(-" + amend + "px)";
      }
    },
    // 二级菜单折叠
    menuFold() {
      if (this.twoMenuShow) {
        this.twoMenuShow = false;
        this.topFoldIcon = "icon-menu-unfold";
      } else {
        this.twoMenuShow = true;
        this.topFoldIcon = "icon-menu-fold";
      }
    },
    // 退出登录
    logout() {
      this.closevisit = true
    },
    closeLyout() {
      this.$store.commit("modifyToken", { token: "", expire: 0 });
      this.$store.commit("modifyRole", "");
      common.setUserInfo("");
      common.setSystenInfo("");
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped lang="scss">
.el-menu {
  border-right-width: 0;

  .el-menu-item {
    padding: 0px;
  }
}

/* 左侧导航 */
// 导航布局
.left-menu {
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 100vh;
  background: #08192E;

  .avater {
    color: #fff;
  }
}

.left-menu-main {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

.left-menu-main::-webkit-scrollbar {
  display: none;
  /* Webkit Browser */
}
.el-menu-vertical-demo {
  // height: calc(100vh - 60px);
  background: #08192E;
  color: #fff;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  // height: calc(100vh - 60px);
  min-height: 400px;
}
// 导航条logo
.left-menu-logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #08192E;
}

/* 顶部导航 */
// 导航布局
.top-nav {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

/* 顶部菜单 */
// 菜单布局
.top-menu {
  padding: 0px 20px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #fff;
}

// 面包屑区域
.top-menu-left {
  display: flex;
  align-items: center;

  // 折叠图标
  .top-fold-icon {
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }

  // 面包屑
  .top-breadcrumb {
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #515a6e;
  }
}

// 用户信息区域
.top-menu-right {
  margin-right: 5px;
  display: flex;
  align-items: center;

  // 头像
  .top-menu-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  // 昵称和下拉区域
  .top-menu-dropdown {
    cursor: pointer;
  }

  .top-dropdown-icon {
    margin-left: 5px;
    vertical-align: -3px;
  }
}

/* 顶部标签 */
// 标签布局
.top-tab {
  padding: 0px 20px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #f6f6f6;

  // 左右箭头
  .tab-icon {
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }
}

.tab-view {
  height: 50px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.tab-list {
  width: fit-content;
  position: relative;
  white-space: nowrap;
  transition: all 1s;
}

// 标签元素
.tab-item {
  padding: 0px 30px;
  width: fit-content;
  height: 38px;
  display: inline-block;
  line-height: 38px;
  color: #515a6e;
  font-size: 14px;
  mask: url("~@/assets/layout/top_tab.png");
  mask-size: 100% 100%;
  cursor: pointer;

  // 悬停时
  &:hover {
    padding: 0px 30px 0px 30px;
    background: #dee1e6;

    .tab-item-close {
      width: 12px;
    }
  }

  // 左偏移
  &.move-left {
    margin-left: -15px;
  }

  // 选中时
  &.active {
    background: #e8f4ff;
    color: #1890ff;

    .tab-item-close {
      width: 12px;
    }
  }

  // 图标
  .item-icon {
    vertical-align: -3px;
  }

  // 关闭按钮
  .tab-item-close {
    margin-left: 7px;
    padding: 1px;
    width: 0px;
    vertical-align: -2px;
    transition: all 0.2s;

    &:hover {
      border-radius: 50%;
      color: #fff;
      background: #c0c4cc;
    }
  }
}



.menuList {
  display: flex;
  align-items: center;
}

.el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item {
  padding: 0px;
}

.el-menu-item-group__title {
  padding: 0px;
  display: flex;
}

::v-deep .el-badge__content {
  // transform: scale(0.75);
  min-width: unset;
  /* 可能需要重置最小宽度 */
  // line-height: normal; /* 行高调整 */
  font-size: 8px;
  /* 更小的字体 */
}
</style>
