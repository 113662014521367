<template>
    <div>
        <el-dialog title="提示" v-model="dialogVisible"  width="30%" @close="closeDialog"
            :close-on-click-modal="false">
            <div style='display:flex; justify-content: center; align-items: center;flex-direction: column;'>
                <span style='font-size:16px;margin-bottom:30px;'>请点击下方按钮下载文件</span>
                <el-button type="primary" size="mini" @click="uploadFile()">立即下载</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            url: '',
        }
    },
    components: {

    },
    mounted() {
    },
    methods: {
        show() {
           this.dialogVisible = true 
        },
        closeDialog() {
            this.dialogVisible = false
            localStorage.setItem('url', '')
        },
        //下载文件
        uploadFile() {
            if (localStorage.getItem('url')) {
                window.open(localStorage.getItem('url'))
                localStorage.setItem('url', '')
                this.dialogVisible = false
            } else {
                this.$message.warning('下载错误请重新下载')
                localStorage.setItem('url', '')
            }
        },
    },
}

</script>
<style scoped lang='less'></style>