import axios from '@/common/axios'
import store from '@/store'
import conf from '@/common/conf.js'
import md5 from 'js-md5'
import define from "@/common/define"
// 登录
const login = (form) => {
    // 整理格式
    let params = {}
    params.username = form.username.trim()
    params.password =md5(form.password) 
    params.captcha = form.captcha
    params.phone = form.phone ? form.phone : ''
    params.level = form.level
    params['V1-Admin-Captcha-Id'] = form['V1-Admin-Captcha-Id']
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/login',
        method: 'post',
        data
    })
}

// 验证码
const getCaptcha = (data) => {
    let time = Date.parse(new Date()) / 1000
    let params = { param: {}, time: time }
    params.param = define.defineParam(data, time)
    return axios({
        url: conf.apiUrl + '/captcha',
        method: 'get',
        params
    })
}

// 获取用户信息
const getUserInfo = () => {
    return axios({
        url: conf.apiUrl + '/member/info',
        method: 'get',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` }
    })
}

// 获取用户前端信息
const getUserWechat = (params) => {
    let time = Date.parse(new Date()) / 1000
    let data = { param: {}, time: time }
    data.param = define.defineParam(params, time)
    return axios({
        url: conf.apiUrl + '/weixin.Fans/fansId',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 获取系统参数
const getSystemInfo = () => {
    return axios({
        url: conf.apiUrl + '/files/indexset',
        method: 'get',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` }
    })
}

// 云控跳转 登录接口
const getCloud = (data) => {
    return axios({
        url: conf.apiUrl + '/adminLogin',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}


// 输出
export default {
    login,
    getCaptcha,
    getUserInfo,
    getUserWechat,
    getSystemInfo,
    getCloud
}
